<template>
  <div>
    <a-modal
      :visible="visible"
      :title="planType"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
            :rows="3"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { maintenanceRecordCreate } from "@/api/equipment";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  computed: {
    planType() {
      if (this.item.maintenance_plan_item.type === "inspection") {
        return "点检";
      }

      if (this.item.maintenance_plan_item.type === "maintenance") {
        return "保养";
      }

      return null;
    },
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          maintenanceRecordCreate({ maintenance_task: this.item.id, ...values })
            .then((data) => {
              this.$emit("create", data);
              this.$message.success(`${this.planType}成功`);
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
